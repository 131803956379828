<template>
  <v-dialog
    persistent
    v-model="dialog"
    overlay-color="black"
    overlay-opacity="0.5"
  >
    <div class="tw-flex tw-w-full tw-flex-col">
      <div
        style=" width: 30px; height: 30px; border-radius: 50%; z-index: 1"
        class="tw-flex tw-justify-center  tw-items-center tw-self-end  tw-cursor-pointer"
        @click="$emit('close')"
      >
        <vue-feather type="x" style="color: white; " />
      </div>
      <div v-if="section === 'main'"
        class=" tw-flex card tw-p-9 tw-justify-center tw-items-center tw-flex-col"
        style="min-height: 384px"
      >
        <img :src="check" />

        <p class="title tw-py-5 tw-mb-0" :style="{color:color}">
          {{ title }}&nbsp;<span v-if="userName">{{ userName }}</span>
        </p>
        <label
          class="desc "
          v-if="description"
          :class="!additionalText ? 'tw-pb-5' : ''"
          >{{ description }}</label
        >
        <label class="desc tw-pb-5 tw-mt-5" v-if="additionalText" :style="{color:color}" >{{
          additionalText
        }}</label>
        <a
          v-if="bottomText && downloadUrl"
          :href="downloadUrl"
          target="_blank"
          class="bottomtext tw-mt-5"
        >
          {{ bottomText }}
        </a>

        <router-link
          class="bottomtext tw-mt-5"
          :to="{ name: link }"
          v-else-if="bottomText"
        >
          {{ bottomText }}
        </router-link>
      </div>
      <div class=" tw-flex card  tw-justify-center tw-items-center tw-flex-col tw-p-8"
           style="min-height: 340px" v-if="section === 'cancelling'">
        <p class="cancel-title tw-justify-start tw-flex tw-items-start tw-w-full tw-pb-3"
           :style="{color:color}">Cancel Trip</p>
         <v-textarea :color="color" rows="6" outlined hide-details
                     label="State reason for cancelling"
                     class="tw-w-full" style="font-family: Inter, sans-serif!important;" v-model="reason">
         </v-textarea>

        <div class="tw-flex tw-w-full tw-justify-around tw-items-center action-btn-div">
          <v-btn
              text
              class="first-btn"
              @click="$emit('close')"
              :style="{
              color: color,
              border: '1px solid' + color,}">
            Close
          </v-btn>
          <v-btn
              text
              class="second-btn"
              @click="handleCancelTrip"
              :style="{ background: color, color: 'white' }"
              :loading="loading">
            Cancel Trip
          </v-btn>
        </div>
      </div>

    </div>
  </v-dialog>
</template>

<script>
import check from "@/assets/check.svg";

export default {
  name: "Modal",
  components: {  },
  props: {
    dialog: Boolean,
    icon: {
      type: String,
      default: "check",
    },
    title: {
      type: String,
      default: "Title",
    },
    description: {
      type: String,
      default: "",
    },
    additionalText: [String],
    userName: [String],
    bottomText: [String],
    link: {
      type: String,
      required: false,
      default: "Dashboard",
    },
    downloadUrl: [String],
    color: {
      type: [String],
      default: "#F66B05",
    },
    section:{
      type: String,
      default : "main"
    },
    loading:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      check,
      reason:null
    };
  },
  methods:{
    handleCancelTrip(){
     if (!this.reason){
       this.$displaySnackbar({
         message: "Reason for cancelling cannot be empty",
         success: false,
       });
     }
     else {
       this.$emit('cancelTrip',this.reason)
     }
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .v-dialog {
  box-shadow: none !important;
  width: 80% !important;
  @media screen and (min-width: 768px) {
    width: 30% !important;
  }
}
.card {
  width: 97%;
  background-color: white;
  border-radius: 20px;

}
.title {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 36px !important;
  margin-bottom: 0 !important;
  line-height: 44px;
  text-align: center;
  color: #004aad;
}

.cancel-title {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px !important;
  margin-bottom: 0 !important;
  line-height: 24px;
  text-align: left;
}
.desc {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 18px !important;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #333333;
}

.bottomtext {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #004aad;
  text-decoration: none;
}

.second-btn{
  text-transform: none;
}
.first-btn{
  text-transform: none;
}
</style>
